import { getAppConfig } from '@services/config';
import { externalHttpService } from '@services/http';
import { CACHE_TAGS, HttpService } from '@services/http/http';

import {
    AverageResponseType,
    ReviewAverageType,
    ReviewResponseType,
    ReviewsInfoType,
    ReviewsResponseType,
} from '@app-types/review';

import { mapToReviewInfo } from '@helpers/review';

type SkeepersRepositoryConfig = {
    url: string;
    id: string;
    ttl: number;
};

export class SkeepersRepository {
    private httpService: HttpService;
    private configuration: SkeepersRepositoryConfig;

    public constructor(httpService: HttpService, configuration: SkeepersRepositoryConfig) {
        this.httpService = httpService;
        this.configuration = configuration;
    }

    public async getAverageReviews(gencodes: string[]): Promise<ReviewAverageType[] | undefined> {
        return this.httpService
            .post<AverageResponseType>(
                `${this.configuration.url}/product`,
                {
                    query: 'average',
                    idWebsite: this.configuration.id,
                    plateforme: 'fr',
                    product: 'all',
                },
                {
                    ttl: this.configuration.ttl,
                    tags: [CACHE_TAGS.PRODUCTS],
                },
            )
            .then((averages) => {
                if (averages) {
                    const result: ReviewAverageType[] = [];
                    for (const key in averages) {
                        if (averages[key] && gencodes.includes(key)) {
                            result.push({
                                gencode: key,
                                rate: Number(averages[key]?.[0]?.rate) * 2,
                                count: Number(averages[key]?.[0]?.count),
                            });
                        }
                    }

                    return result;
                }

                return undefined;
            })
            .catch(() => undefined);
    }

    public async getReviews(
        gencode: string,
        offset: number = 0,
        limit: number = 5,
    ): Promise<ReviewsInfoType | undefined> {
        return this.httpService
            .get<ReviewsResponseType>(
                `${this.configuration.url}/product`,
                {
                    query: 'reviews',
                    idWebsite: this.configuration.id,
                    plateforme: 'fr',
                    product: gencode,
                },
                {
                    ttl: this.configuration.ttl,
                    tags: [CACHE_TAGS.PRODUCT_DETAIL],
                },
            )
            .then((reviews) => {
                if (reviews) {
                    if (reviews[0]) {
                        const reviewData: ReviewResponseType[] = reviews[0].reviews;

                        return mapToReviewInfo(reviewData, offset, limit);
                    }
                } else {
                    throw new Error('No reviews found');
                }
            })
            .catch(() => undefined);
    }
}
const config = getAppConfig();

export const skeepersRepository = new SkeepersRepository(externalHttpService, {
    url: config.skeepers.url!,
    id: config.skeepers.id!,
    ttl: parseInt(config.cache.ttl.productDetail),
});
