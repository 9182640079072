import { AccordionBody, AccordionHeader, AccordionItem, Text, TextLevels, TypographyBold } from '@bytel/trilogy-react';

import { FundingEnumType, FundingType } from '@app-types/funding';

import { InlinePrice } from '@components/inline-price';

import { isCofidis, isYounited } from '@helpers/funding';

type FundingLegalsProps =
    | {
          funding: FundingType;
          type?: never;
      }
    | {
          type: FundingEnumType.YOUNITED | FundingEnumType.COFIDIS;
          funding?: never;
      };

export function FundingLegals({ type, funding }: Readonly<FundingLegalsProps>) {
    if (funding) {
        type = isYounited(funding.type) ? FundingEnumType.YOUNITED : FundingEnumType.COFIDIS;
    }

    return (
        <AccordionItem id='product-detail-funding-legals' data-cy='funding-legals' open>
            <AccordionHeader>Mentions légales du financement</AccordionHeader>
            <AccordionBody>
                <Text typo={TypographyBold.TEXT_WEIGHT_SEMIBOLD} level={TextLevels.ONE}>
                    Un crédit vous engage et doit être remboursé. Vérifiez vos capacités de remboursement avant de vous
                    engager.
                </Text>
                {funding && (
                    <Text level={TextLevels.TWO}>
                        <b>{funding.installmentCount} mensualités de </b>
                        <InlinePrice amount={funding.monthlyAmount} period='mois' level={TextLevels.TWO} />.{' '}
                        <b>
                            Montant total dû <InlinePrice amount={funding.totalFinancingCost} level={TextLevels.TWO} />
                        </b>{' '}
                        (hors assurance facultative). <b>TAEG fixe&nbsp;: {funding.APR?.toLocaleString()}%</b>. Taux
                        débiteur fixe de {funding.fixedInterestRate?.toLocaleString()}%.
                    </Text>
                )}
                {isYounited(type!) && <YounitedLegals />}
                {isCofidis(type!) && <CofidisLegals />}
            </AccordionBody>
        </AccordionItem>
    );
}

function YounitedLegals() {
    return (
        <>
            <Text level={TextLevels.TWO}>
                Younited est agréé en tant qu&apos;établissement de crédit - Prestataire de Services
                d&apos;Investissement par l&apos;Autorité de Contrôle Prudentiel et de Résolution (ACPR, n°
                d&apos;agrément&nbsp;: 16488).
            </Text>
            <Text level={TextLevels.TWO}>
                Le financement est accordé sous réserve d&apos;acceptation définitive après l&apos;étude de votre
                dossier. Vous disposez d&apos;un droit de rétractation de 14 jours calendaires révolus à compter de la
                signature de l&apos;offre de contrat de crédit.
            </Text>
            <Text level={TextLevels.TWO}>
                Younited&nbsp;: SA à Directoire et Conseil de Surveillance au capital de 2&nbsp;000&nbsp;785€ - Siège
                social&nbsp;: 21 rue de Châteaudun - 75009 PARIS - RCS de Paris 517&nbsp;586&nbsp;376 - Numéro ORIAS
                11061269
            </Text>
        </>
    );
}

function CofidisLegals() {
    return (
        <>
            <Text level={TextLevels.TWO}>
                COFIDIS est agréé en tant qu&apos;établissement de crédit par l&apos;Autorité de Contrôle Prudentiel et
                de Résolution (ACPR, n° d&apos;agrément&nbsp;: 14940), 4 place de Budapest – CS 92459 – 75436 Paris
                CEDEX 09.
            </Text>
            <Text level={TextLevels.TWO}>
                Le financement est accordé sous réserve d&apos;acceptation définitive après l&apos;étude de votre
                dossier. Vous disposez d&apos;un droit de rétractation de 14 jours calendaires révolus à compter de la
                signature de l&apos;offre de contrat de crédit.
            </Text>
            <Text level={TextLevels.TWO}>
                COFIDIS&nbsp;: Société Anonyme à Directoire et Conseil de surveillance au capital de 67 500 000 € –
                Siège social&nbsp;: Parc de la Haute Borne, 61 avenue Halley, 59866 Villeneuve d&apos;Ascq – RCS Lille
                Métropole 325307106
            </Text>
        </>
    );
}
