'use client';

import { ImageLoaderProps } from 'next/dist/shared/lib/image-config';
import Image from 'next/image';
import { CSSProperties } from 'react';

import { classNames } from '@bytel/product-wall/helpers';

import { getAppConfig } from '@services/config';

export const POSITION_PRIORITY = 6;
export type ImageProps = {
    src: string;
    alt: string;
    width: number;
    height: number;
    style?: CSSProperties;
    position?: number;
    id?: string;
    className?: string;
    fetchPriority?: 'high' | 'low' | 'auto';
};

const config = getAppConfig();

export default function thumborLoader({ src }: ImageLoaderProps) {
    if (config.thumbor.active) {
        return `${config.thumbor.url}/full${src}`;
    }

    return `${config.assets.images.url}${src}`;
}

export function ImageThumbor({ position = 99, alt, ...props }: ImageProps) {
    return (
        <div className='image is-flex is-centered'>
            <Image priority={position < POSITION_PRIORITY} loader={thumborLoader} alt={alt} {...props} />
        </div>
    );
}

export function AccessoryImageThumbor({ className = '', ...props }: ImageProps) {
    return <ImageThumbor className={classNames(className, 'accessory-image')} {...props} />;
}
